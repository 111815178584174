<template>
  <div>
    <Card>
      <template slot="outer">
        <PageTopOuter
          icon="topic"
          label="Writing"
          :search-box="false"
          class="mt-4"
          :show-dialog="false"
          @on-create="onCreateNew"
        />
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <!-- it will be Sort by date -->
          <SelectComponent
            :placeholder="false"
            class="w-64 m-2"
            :items="statuses"
            return-type="index"
            @getIndex="getStatus"
          />
          <SearchBox
            v-model="keyword"
            placeholder="Search"
            class="p-2 w-64 m-2"
            @submit="submitSearchKeyword"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="questions" hover-action @sort="setSortQuestion">
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-sort-by="q_id" md-label="Q_ID">{{ item.q_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="INDEX"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-label="USED FOR" class="text-victoria ">
              <span @click="showUsedForList(item.id)" class="table-cell-link"> View all </span>
            </md-table-cell>
            <md-table-cell md-label="FREQUENCY">
              <md-icon class="m-0" :class="getIconColor(item.frequency)">{{ (item.frequency) ? 'check': '' }}
                <md-tooltip v-if="item.frequency" md-direction="top">{{ item.frequency }}</md-tooltip>
              </md-icon>
            </md-table-cell>
            <md-table-cell md-sort-by="created_at" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell  md-sort-by="created_by" md-label="CREATED BY" class="text-victoria ">
              <span @click="showCreatedByProfile(item.creator)" class="table-cell-link">{{ item.created_by }}</span>
            </md-table-cell>
            <md-table-cell md-sort-by="active" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Published' : 'Draft' }}</md-table-cell>
            <md-table-cell>
              <span class="action flex">
                <md-icon @click.native="onDetails(item.id)" class="bg-blue-chill rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
                <md-icon @click.native="onEdit(item.id)" class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
                <md-icon @click.native="deleteQuestion(item.id)" class="bg-tahiti-gold text-white rounded-full p-1 text-base">delete</md-icon>
              </span>
            </md-table-cell>
            
          </template>
        </Table>
      </CardBody>
    </Card>
    <div class="flex justify-end my-6" v-if="questions.length > 20">
      <Paginate
       :start="paginate.start"
       :end="paginate.end"
       :total="paginate.total"
       :limit="paginate.limit"
        @on-start="onStart"
        @on-end="onEnd"
      />
    </div>
    <ConfirmDialog 
      @confirm="actDeleteQuestion(questionId)"
      :active.sync="active"
    />
  </div>
</template>

<script>
import {
  Card,
  CardBody,
  SelectComponent,
  PageTopOuter,
  ConfirmDialog,
  Table,
  Paginate } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import cms from "@/data/cms"
import { find } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { query } from "@/utils/queryString";
import { paginate } from "@/utils/paginate";
export default {
  name: "Todo-List",
  components: {
    Card,
    Paginate,
    CardBody,
    PageTopOuter,
    ConfirmDialog,
    SelectComponent,
    SearchBox,
    Table
  },
  data() {
    return {
      active: false,
      questionId: null,
      keyword: '',
      tableLabel: '',
      selectedValue: null,
      pagination: true,
      paginate: paginate.get(),
      frequencyStyles: cms.frequency,
    }
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
    }
  },
  computed: {
    ...mapGetters({
      questions: "question/getSectionWiseQuestion",
      statuses: "cms/getStatuses",
    }),
  },
  methods: {
    ...mapMutations({
      setSortQuestion: "question/setSortQuestion"
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actSectionWiseQuestion: "question/actSectionWiseQuestion",
      actGetDropdowns: "cms/actGetDropdowns",
      actDeleteQuestion: "question/actDeleteQuestion",
      actQuestionDetails: "question/actQuestionDetails",
      actGetUserInformation: "user/actGetUserInformation",
    }),
    onSelect() {

    },
    onEdit(questionId) {
      this.$router.push({
        name: 'pte-question.edit',
        params: {
          id: questionId
        },
        query: {
          type: 'writing'
        }
      })
    },
    submitSearchKeyword() {
      this.actSectionWiseQuestion(`?${query.get('search', this.keyword)}`);
    },
    onDetails(questionId) {
      this.$emit('on-click', {
        component: 'QuestionDetails'
      });
      this.actQuestionDetails(questionId);
    },
    showUsedForList(questionId) {
      this.$emit('on-click', {
        component: 'QuestionUsedForList'
      });
      this.actQuestionDetails(questionId);
    },
    showCreatedByProfile(createdById) {
      this.$emit('on-click', {
        component: 'CreatedByProfile'
      });
     this.actGetUserInformation(createdById);
    },

    deleteQuestion(questionId) {
      this.active = true;
      this.questionId = questionId;
    },
    getIconColor(frequency) {
      let frequencyObject = find(this.frequencyStyles, ['id', frequency]);
      return frequencyObject ? frequencyObject.color : ''
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
    getStatus(index) {
      this.selectedValue = this.toLower(this.statuses[index].id);
      if(this.selectedValue == 'all') {
        query.remove('active');
      }else {
        query.set('active', this.selectedValue);
      }
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
    },
    onCreateNew() {
      this.$router.push({
        name: 'pte-question.create',
        query: {
          type: 'writing'
        }
      });
    }
  },
  created() {
    query.set('type', 'question');
    query.set('section', 'writing');
    this.actSectionWiseQuestion(`?${query.get()}`);
    this.actGetDropdowns(`?status`);
  }
};
</script>